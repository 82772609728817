.work {
  display: flex;
  font-family: 'Varela', sans-serif;
}
.projects {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;
  padding: 4rem 1rem;
}

.projects h2 {
font-size: 2rem;
margin-bottom: calc(2rem * 1);
color: black;
}
.projects h3 {
  font-size: 1.5rem;
  color: black;
}

.projects h4 {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(139, 137, 137, 0.502);
}

.projects h5 {
  font-size: .9rem;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 3rem;
}

.text {
  width: 50%;
}

.textbox {
  border-radius: 10px;
  font-size: .8rem;
  line-height: 1.5;
}

.projects article:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.projects ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 1rem;
}

.projects img {
  margin-top: 1rem;
  border-radius: 25px;
  object-fit: cover;
  width: 50%;
  border: 1px solid lightgray;
}

.projects video {
  margin-top: 1rem;
  border-radius: 25px;
  border: 1px solid lightgray;
  width: 50%;
}

.work {
  border-radius: 15px;
  padding: 1rem;
}

.reactime a {
  text-decoration: none;
  color: black;
}

.work-links {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
}

.work-links button {
  padding: .5rem;
  border-radius: 10px;
  border: 1px black;
  font-size: 1rem;
  cursor: pointer;
  padding: 1rem;
}

.work-links a {
  text-decoration: none;
}


@media (min-width: 640px) and (max-width:912px) {

} 


@media (max-width:750px) {
  .work {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .projects {
    padding: 2rem 1rem;
  }
  .text {
    width: 100%;
  }
  .projects img {
    width: 100%;
  }
  .projects video {
    width: 100%;
  }
}