@import url('https://fonts.googleapis.com/css2?family=Varela&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css');


.Home {
  display: flex;
  background-color: #FFFFFF;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #52525B;
  padding: 2rem;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5); */
  font-family: 'Varela', sans-serif;
  margin: 0 auto;
  max-width: 100vw;
}

.Home a:hover {
   color:rgb(180, 18, 216);
  /* border-bottom: 1px solid aqua; */
}

.bio {
  width: 100%;
}

.bio h1 {
  font-size: 2rem;
  color: black;
}

.bio a {
  text-decoration: none;
}

.picture-div-container {
  margin-top: 4rem;
}

.picture-div {
  display: flex;
  justify-content: center;
  overflow: hidden;
  gap: 1.25rem;
  margin: -1rem 0;
  padding: 1rem;
  flex-flow: wrap;
}

.picture-div img {
  position: relative;
  flex: none;
  width: 11rem;
  border-radius: 10px;
  object-fit: cover;
  transform: rotate(-2deg);
}

.picture-div img:first-child {
  transform: rotate(2deg);
}

.picture-div img:nth-child(3) {
  transform: rotate(2deg);
}

@media (min-width: 640px) {

  .Home {
    max-width: 1200px;
    margin: 0 auto;
  }
  .bio {
  width: 75%;
}
  .picture-div-container {
  margin-top: 4rem;
}
.picture-div {
  gap: 2rem;
}
  .picture-div img {
    width: 16rem;
    overflow: hidden;
  }
} 

@media (min-width: 640px) and (max-width:912px) {

  .Home {
    max-width: 800px;
    margin: 0 auto;
  }
  .picture-div-container {
  margin-top: 4rem;
}
.picture-div {
  gap: 2rem;
}
  .picture-div img {
    max-width: 100%;
    overflow: auto;
  }
} 


@media (max-width:639px) {
  .Home {
    max-width: 100vw;
    overflow-x: hidden;
  }
  .picture-div img {
    width: 45%;
}
}