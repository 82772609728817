.Contact {
  display: flex;
  background-color: #FFFFFF;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: black;
  padding: 2rem;
  font-family: 'Varela', sans-serif;
  margin: 0 auto;
  max-width: 100vw;
}

.Contact a:hover {
   color:rgb(180, 18, 216);
}

.Contact h1 {
  text-align: center;
}

.profile-pic-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1rem;
}

.profile-pic {
  display: flex;
  width: 20vw;
  align-items: center;
}

.profile-pic img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.connect-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  margin: 1rem;
  align-items: center;
  text-align: center;
}

.connect {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.connect p {
  color: #52525B;
}

.connect-pic {
  width: 100%;
  padding: 1rem;
}

.connect-pic img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 10px;
}


@media (min-width: 550px) {

  .Contact {
    max-width: 1200px;
    margin: 0 auto;
  }

  .profile-pic {
    width: 15vh;
  }
  .connect-container {
      flex-direction: column;
      align-items: left;
    }
     .about-me {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
} 

@media (min-width:850px) {

  .Contact {
      overflow-x: hidden;
  }
 .connect {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.connect p {
  color: #52525B;
}

.connect-pic {
  width: 60%;
  padding: 1rem;
}
  
}
