nav {
  border-bottom: 1px solid rgba(139, 137, 137, 0.502);
  padding: 1rem;
  font-size: 90%;
  max-width: 1200px;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-end;
  gap: 2rem;
}

nav li:first-child {
  display: flex;
  width: 100%;
  justify-content: center;
}

nav a {
  color: var(--white);
  text-decoration: none;
  display: block;
}

.profile-pic {
  display: flex;
  align-items: center;
  height: 10rem;
}


@media (min-width: 550px) {
  nav {
    max-width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
  }

  nav li:first-child {
    justify-content: flex-start;
    margin-right: auto;
    flex-basis: 40%;
  }
}

@media (max-width: 639px) {
  .profile-pic {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
