footer {
  text-align: center;
  padding: .5rem 0;
  border-top: 1px solid rgba(139, 137, 137, 0.502);
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
}

footer ul {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 3rem;
  font-size: 1.5rem;
}

footer p {
  font-size: 1rem;
}

footer a {
  color: black;
}

footer a:hover {
  color:rgb(180, 18, 216);
  /* border-bottom: 1px solid aqua; */
}
