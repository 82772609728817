.About {
  display: flex;
  background-color: #FFFFFF;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: black;
  padding: 2rem;
  font-family: 'Varela', sans-serif;
  margin: 0 auto;
  max-width: 100vw;
}

.About a:hover {
   color:rgb(180, 18, 216);
}

.about-me-container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.about-me {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.about-me p {
  color: #52525B;
}
.about-me li {
  color: #52525B;
}

.about-me h4 {
  margin: 1rem 0;
}

.about-me img {
  width: 75%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  align-items: flex-end;
  /* transform: rotate(-2deg) */
}

.about-me-pic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 75%;
  height: auto;
  padding: 1rem;
}

.about-me-pic img {
  width: 75%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  /* transform: rotate(2deg); */
}

.about-me-pic img:nth-child(2) {
  /* transform: rotate(-2deg); */
}

.about-me-pic .hunter {
/* transform: rotate(2deg) !important; */
}

.asus-setup {
  padding: 1rem;
}
.asus-setup img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}


@media (min-width:850px) {
  .About {
    overflow-x: hidden;
       max-width: 1200px;
  }

  .about-me {
  display: flex;
  flex-direction: column;
  width: 100%;
}
  .about-me-container {
    flex-direction: row;
    align-items: flex-start;
  }

}
